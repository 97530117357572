<template>
<div v-if="pack?.matureRating && ageStatus !== 'approved'" class="age-gate">
    <AgeGate :status="ageStatus" v-on:status-update="updateStatus" />
</div>
<div v-else class="pack-details">
    <section class="grid vertical-center halves hero-section">
        <div class="hero" :style="heroStyles">
            <div v-if="pack && pack.matureRating" class="ratings-icon">
                <RatingsIcon />
            </div>
            <div class="shopping-overlay clamped" :class="{wishlist: pack?.displayWishlistUI}">
                <PackShoppingOverlay
                    v-if="(product && pack) || pack?.displayEmailSignup" :pack="pack"
                    :product="product" :show-platforms="false" :fell-back-to-en="fellBackToEn"
                    :is-bundle="false" :is-localized="isLocalized"
                />
            </div>
        </div>
        <div class="xs-spacer" />
    </section>
    <div class="background-gradient">
        <div class="shop-section clamped">
            <section class="storefronts padded">
                <StorefrontTile
                    v-for="(platform, idx) in platformOptions" :key="idx" :pack="pack?.slug"
                    :platform="platform" :product="product" :fell-back-to-en="fellBackToEn"
                    :is-bundle="false" :is-localized="true"
                />
            </section>
        </div>
        <div v-if="pack?.promotional?.data" class="media-section clamped">
            <h2 class="section-heading title-6">
                {{ $t('PACK_DETAIL.TRAILERS') }}
            </h2>
            <section class="media mobile">
                <Carousel
                    :media="pack?.promotional?.data" :first-img-trailer="pack?.trailerVideoId"
                    v-on:item-clicked="carouselClick"
                />
            </section>
        </div>
        <div class="games-section clamped">
            <h3 class="section-heading title-6">
                {{ $t('PACK_DETAIL.FEATURED_TITLES', {numGamesInPack: gameDetails?.length}) }}
            </h3>
            <section class="game-details">
                <div v-for="(game, idx) in gameDetails" :key="idx" class="game-in-pack">
                    <div class="pack-game">
                        <div
                            class="cropped-hero-container"
                            :class="{ reverse: ((idx % 2) === 1) }"
                        >
                            <img
                                :src="gameHeroSrc(game)" :alt="gameHeroAlt(game)" class="cropped-hero-tile"
                                loading="lazy"
                            >
                        </div>
                        <div
                            class="flex-column information-container"
                            :class="{ reverse: ((idx % 2) === 1) }"
                        >
                            <div class="wordmark">
                                <img
                                    :src=" `${game.wordmark?.data?.attributes.url}?format=auto&width=500`"
                                    :alt="game.wordmark?.data?.attributes.alternativeText"
                                    loading="lazy"
                                    v-on:load="setImageOrientationClass"
                                >
                            </div>

                            <div class="tag-container flex-row">
                                <Tag
                                    v-if="game.features.types"
                                    :text="gameType(game)?.name"
                                    :icon="gameType(game)?.icon"
                                    feature="gameType"
                                />
                                <Tag
                                    v-if="game.features.counts"
                                    :text="`${game.features.counts.playerMin} -
                                        ${game.features.counts.playerMax}`"
                                    feature="playerRange"
                                    icon="fa fa-regular fa-user-group"
                                />
                                <Tag
                                    v-if="game.features.durationAvg"
                                    :text="game.features.durationAvg.toString()"
                                    feature="duration"
                                    icon="fa fa-regular fa-clock-eleven-thirty"
                                />
                            </div>

                            <p class="description" :class="{right: ((idx % 2) === 0)}">
                                {{ game.shortDescription }}
                            </p>
                            <div class="btn-container flex-column">
                                <ButtonElement
                                    v-if="game.trailerVideoId" class="outline"
                                    v-on:click="trailerClick(game)"
                                >
                                    {{ $t('GAME_DETAIL.WATCH_TRAILER') }}
                                </ButtonElement>
                                <NuxtLink :to="localeRoute(`/games/${pack.slug}/${game.slug}`)">
                                    <ButtonElement class="primary learn-more">
                                        {{ $t('GAME_DETAIL.LEARN_MORE') }}
                                    </ButtonElement>
                                </NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="about-section clamped">
            <h4 v-t="'PACK_DETAIL.ABOUT'" class="section-heading title-6" />
            <section class="about">
                <div
                    class="padded about-container"
                    :class="{'flex-row': windowWidth > 959, 'flex-column': windowWidth < 960}"
                >
                    <!--label-->
                    <div class="description">
                        <p class="heading">{{ $t('PACK_DETAIL.DESCRIPTION') }}</p>
                        <p v-md="pack?.description" />
                    </div>
                    <div v-if="specs" class="specs">
                        <p class="heading">{{ $t('PACK_DETAIL.SPECS') }}</p>
                        <div class="attributes">
                            <div class="details flex-column">
                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.PLAYER_COUNT_HEADER') }}</p>
                                    <p v-if="specs.playerCounts" class="right">
                                        {{
                                            $t('PACK_DETAIL.PLAYER_COUNT_RANGE', {
                                                playerMin: Math.min(...specs.playerCounts),
                                                playerMax: Math.max(...specs.playerCounts)
                                            })
                                        }}
                                    </p>
                                </div>
                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.DURATION_HEADER') }}</p>
                                    <p v-if="specs.durations" class="right">
                                        {{
                                            $t('PACK_DETAIL.DURATION_RANGE', {
                                                durationMin: Math.min(...specs.durations),
                                                durationMax: Math.max(...specs.durations)
                                            })
                                        }}
                                    </p>
                                </div>
                                <div class="detail">
                                    <p>{{ $t('PACK_DETAIL.GAME_TYPES') }}</p>
                                    <p v-if="specs.types" class="right">
                                        {{
                                            (specs.types.filter(
                                                (type: string, idx: number) => specs?.types.indexOf(type) === idx
                                            )).join(', ')
                                        }}
                                    </p>
                                </div>
                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.FAMILY_FRIENDLY') }}</p>
                                    <p v-if="specs.ffSetting && (specs.ffSetting.indexOf(true) > -1)">Optional</p>
                                    <i v-else class="fa-solid fa-circle-xmark" />
                                </div>
                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.MODERATION') }}</p>
                                    <div v-if="specs.modSetting">
                                        <i
                                            v-if="specs.modSetting.indexOf(true) > -1"
                                            class="fa-solid fa-circle-check"
                                        />
                                        <i v-else class="fa-solid fa-circle-xmark" />
                                    </div>
                                </div>
                                <div class="detail">
                                    <p>{{ $t('PACK_DETAIL.AUD_PLAY_ALONG') }}</p>
                                    <template v-if="specs.audienceSetting">
                                        <p
                                            v-if="specs.audienceSetting.indexOf(true) > -1"
                                            class="right audience-count"
                                        >
                                            {{ $t('PACK_DETAIL.AUD_CAPACITY') }}
                                        </p>
                                        <i v-else class="fa-solid fa-circle-xmark" />
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import type { Component } from 'vue'
import Shopify from '@jackboxgames/shopify'
import { getStorefrontsFromProductData } from '$services/storefronts/storefronts'
import { I18n } from '$services/i18n'
import { previousLocale } from '$plugins/i18n/plugin'
import Tag from '$components/Tag.vue'
import ButtonElement from '$components/ButtonElement.vue'
import Carousel from '$components/carousel/Carousel.vue'
import CarouselModal from '$components/carousel/CarouselModal.vue'
import TrailerModal from '$components/TrailerModal.vue'
import PackShoppingOverlay from '$components/shopping/PackShoppingOverlay.vue'
import StorefrontTile from '$components/shopping/StorefrontTile.vue'
import RatingsIcon from '$components/ratings/RatingsIcon.vue'
import AgeGate, { type BirthdayStatus } from '$components/ratings/AgeGate.vue'
import { JBGWeb } from '$types/JBGWeb'
import { Geolocate } from '$services/geolocate'

const app = useNuxtApp()
const i18n = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const windowWidth = ref(800)
const packSlug = route.params.packslug as string
const ageStatus: Ref<BirthdayStatus> = ref('unknown')
const product: Ref<Shopify.Shopify.Product> = ref()
const fellBackToEn = ref(false)
const countryCode = Geolocate.getCountryCode()

const populate = [
    'hero',
    'croppedHero',
    'wordmark',
    'gameDetails.data',
    'gameDetails.title',
    'gameDetails.wordmark',
    'gameDetails.croppedHero',
    'gameDetails.hero',
    'gameDetails.features',
    'gameDetails.features.counts',
    'gameDetails.features.types',
    'gameDetails.features.options',
    'gameDetails.features.languages',
    'promotional',
    'shopifyProduct',
    'trailerVideoId',
    'tile'
]

const { data: pack } = await useAsyncData<JBGWeb.PackDetail>(
    `packdetail-${packSlug}-${i18n.locale.value}`,
    async () => {
        try {
            const pack = await app.$cms.fetchOne<JBGWeb.PackDetail>('pack-details', packSlug, {
                populate,
                locale: i18n.locale.value,
                countryCode
            })
            return pack
        } catch (error) {
            if (error.message === 'No data') {
                try {
                    const pack = await app.$cms.fetchOne<JBGWeb.PackDetail>('pack-details', packSlug, {
                        populate: ['localizations'],
                        locale: previousLocale.value
                    })
                    pack.localizations.data.some((x) => {
                        if (x.attributes.locale === I18n.getLocaleForStrapi(i18n.locale.value)) {
                            void navigateTo(x.attributes.slug, { replace: true })
                            return true
                        }
                        return false
                    })
                } catch (error) {
                    console.error('error loading pack-details localized', error)
                }
                try {
                    const pack = await app.$cms.fetchOne<JBGWeb.PackDetail>('pack-details', packSlug, {
                        populate,
                        locale: 'en',
                        countryCode
                    })
                    fellBackToEn.value = true
                    return pack
                } catch (e) {
                    console.error('error loading pack-details', error)
                    throw error
                }
            }
            console.error('error loading pack-details', error)
            throw error
        }
    }
)
product.value = pack.value.shopifyProduct

let title = ''
if (pack?.value.title) {
    title += `${pack.value.title}`
}
if (i18n.locale.value !== 'en') {
    title += ` (${i18n.t(I18n.getLocaleNameTag(i18n.locale.value as I18n.Locale))})`
}

useHead({
    title,
    meta: [
        {
            property: 'og:title',
            content: title
        },
        {
            property: 'og:image',
            content: pack?.value.tile?.data?.attributes?.url
        }
    ],
    link: [
        {
            rel: 'canonical',
            href: `https://www.jackboxgames.com/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'x-default',
            href: `https://www.jackboxgames.com/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'en',
            href: `https://www.jackboxgames.com/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'fr',
            href: `https://www.jackboxgames.com/fr/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'it',
            href: `https://www.jackboxgames.com/it/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'de',
            href: `https://www.jackboxgames.com/de/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'es-ES',
            href: `https://www.jackboxgames.com/es/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'es',
            href: `https://www.jackboxgames.com/es-XL/games/${packSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'pt-BR',
            href: `https://www.jackboxgames.com/pt-BR/games/${packSlug}`
        }
    ]
})

const gameDetails: ComputedRef<JBGWeb.GameDetail[]> = computed(() => {
    if (!pack.value.gameDetails?.data) return [] as JBGWeb.GameDetail[]
    return pack.value.gameDetails.data.map((detail) => detail.attributes)
})

const heroStyles = computed(() => {
    if (windowWidth.value > 599 && windowWidth.value < 1921) {
        return {
            backgroundImage: `linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
            radial-gradient(199.81% 112.67% at 92.6% 64.19%,
            rgba(26, 31, 38, 0.00) 15.98%, rgba(26, 31, 38, 0.69) 60.91%, #1A1F26 100%),
            url('${pack?.value.hero?.data?.attributes.url}?format=auto')`
        }
    }

    // add right/left gradients on xlarge screens
    if (windowWidth.value > 1920) {
        return {
            backgroundImage: `linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
            radial-gradient(48.81% 114.67% at 49.6% 55.19%, rgba(26, 31, 38, 0) 80.98%, rgba(26, 31, 38, 0.69) 90.91%, rgb(26, 31, 38) 100%),
            url('${pack?.value.hero?.data?.attributes.url}?format=auto')`
        }
    }

    return {
        backgroundImage: `url('${pack?.value.croppedHero?.data?.attributes.url}?format=auto')`
    }
})

const isLocalized = computed(() => {
    if (!gameDetails.value) return false
    // check if game 1 has a list of lanuages; if it does, that pack is localized
    if (gameDetails.value[0]?.features.languages.length > 1) return true
    return false
})

const platformOptions = computed(() => {
    if (!product.value) return
    // don't show third-party platform info if any prerelease UI exists
    if (pack.value.displayEmailSignup || pack.value.displayWishlistUI) return

    return getStorefrontsFromProductData(product.value)
})

interface Specs {
    playerCounts: Array<number>,
    types: Array<string>,
    durations: Array<number>,
    ffSetting: Array<boolean>,
    modSetting: Array<boolean>,
    audienceSetting: Array<boolean>
}

const specs: ComputedRef<Specs> = computed(() => {
    if (!gameDetails.value) return null
    const specs: Specs = {
        playerCounts: [],
        types: [],
        durations: [],
        ffSetting: [],
        modSetting: [],
        audienceSetting: []
    }

    gameDetails.value.forEach(((game) => {
        const features = game.features

        specs.playerCounts.push(features.counts.playerMin)
        specs.playerCounts.push(features.counts.playerMax)

        if (features.durationAvg !== 0) {
            specs.durations.push(features.durationAvg)
        }

        features.types.data.forEach((tag) => {
            specs.types.push(tag.attributes?.name)
        })

        specs.ffSetting.push(features.options.hasFamilyFriendlyMode)
        specs.modSetting.push(features.options.hasModeration)
        specs.audienceSetting.push(features.options.hasAudience)
    }))
    return specs
})

const gameType = computed(() => (game: JBGWeb.GameDetail) => game.features?.types?.data[0]?.attributes)

const gameHeroSrc = computed(() => (game: JBGWeb.GameDetail) => {
    if (windowWidth.value < 959) {
        return `${game.hero?.data?.attributes.url}?format=auto&width=600`
    }
    return `${game.croppedHero?.data?.attributes.url}?format=auto&width=800`
})

const gameHeroAlt = computed(() => (game: JBGWeb.GameDetail) => {
    if (windowWidth.value < 959) {
        return game.hero?.data?.attributes.alternativeText
    }
    return game.croppedHero?.data?.attributes.alternativeText
})

function onResize() {
    if (import.meta.client) {
        windowWidth.value = window.innerWidth
    }
}

function carouselClick(itemIndex: number) {
    void app.$modal.showModal(
        CarouselModal as Component,
        {
            media: pack?.value.promotional?.data,
            videoId: pack?.value.trailerVideoId,
            itemIndex
        }
    )
}

function setImageOrientationClass(e: Event) {
    const image = e.target as HTMLImageElement
    if (!image) return
    if (image.height >= image.width) {
        image.classList.add('portrait')
    } else if (image.height < image.width) {
        image.classList.add('landscape')
    }
}

function trailerClick(game: JBGWeb.GameDetail) {
    void app.$modal.showModal(
        TrailerModal as Component,
        {
            videoId: game.trailerVideoId
        }
    )
}

function updateStatus(value: BirthdayStatus) {
    ageStatus.value = value
}

onMounted(() => {
    if (import.meta.client) {
        onResize()
        window.addEventListener('resize', onResize)
    }
})
onBeforeUnmount(() => {
    if (import.meta.client) {
        window.removeEventListener('resize', onResize)
    }
})

</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.age-gate {
    height: calc(100vh - 430px);
    @include mq-medium {
        height: calc(100vh - 423px);
    }
    @include mq-small-and-less {
        height: calc(100vh - 380px);
    }
}

.about {
    .flex-row {
        .description{
            width: 50%;
            margin-right: 39px;

            @include mq-small-and-less {
                width: 100%;
            }
        }

        .specs {
            width: 50%;
        }
    }
    .heading {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        font-weight: bold;
    }
}

.pack-details {
    color: var(--neutral-100);

    .about-section {
        // footer fix:
        // add some padding to the last section on the page
        // then move it down below the semi-transparant footer
        // for that nice gradient transition.
        padding-bottom: 320px;
        margin-bottom: -266px;

        @include mq-small-and-less {
            padding-bottom: 280px;
            margin-bottom: -255px;
        }
    }

    .about-container {
        .description{
            margin-bottom: 40px;
        }

        .details {
            background: var(--surface-200);
            border-radius: 8px;
            padding: 16px;

            .detail:not(:last-child) {
                border-bottom: 1px solid var(--neutral-500);
            }

            .detail {
                align-items: center;
                display: inline-flex;
                justify-content: space-between;

                .fa-circle-check,
                .fa-circle-xmark {
                    color: var(--primary-200);
                }

                p {
                    padding-top: 12px;
                    max-width: 50%;
                }

                @include mq-medium-and-more {
                    .right {
                        text-align: right;
                    }
                }
            }
        }
    }

    > section {
        background-color: var(--background-gradient-start);
        margin-top: -138px;

        @include mq-large-and-more {
            min-height: 960px !important;
            max-width: 1920px;
            margin: -138px auto 0 auto;
        }

        @include mq-large {
            min-height: 700px;
        }

        @include mq-medium {
            min-height: 650px;
        }

        @include mq-small {
            min-height: 650px;
        }

        @include mq-xsmall {
            margin: -56px 0 0 0;
            min-height: 680px;
            width: 100%;
        }
    }

    .xs-spacer {
        display: none;
        @include mq-xsmall {
            display: block;
            height: 80px;
        }
    }

    .hero {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        height: 100%;
        min-height: 640px;

        @include mq-xsmall {
            height: 164vw;
        }

        .shopping-overlay {
            height: 100%;
            display: grid;
            align-items: center;
            padding: 100px 0 0 38px;
            @include mq-large-and-more {
                padding: 100px 0 0 0;
            }
            @include mq-xsmall {
                padding: 0;
                position: relative;
                bottom: -88px; // height of xs-spacer element + 8px

                &.wishlist {
                    bottom: -135px; // height of xs-spacer element + 55px
                }
            }
        }

        .ratings-icon {
            width: 87.108px;
            height: auto;
            top: 162px;
            right: 80px;

            @include mq-small-and-less {
                width: 60.69px;
            }

            @include mq-small {
                top: 154px;
                right: 32px;
            }

            @include mq-xsmall {
                top: 58px;
                right: 16px;
            }
        }
    }

    .shop-section {
        .storefronts {
            padding: 22px 0 20px 0px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            gap: 12px;
            overflow-x: auto;
        }
    }

    .game-details {
        .game-in-pack {
            padding: 56px 0px 72px 0px;
            @include mq-large {
                margin: 0px 80px;
            }
            @include mq-medium {
                margin: 0px 40px;
            }
            @include mq-small-and-less {
                padding: 0px 0px 50px 0px;

                &:first-child {
                    padding-top: 32px;
                }
            }
            .description {
                margin-bottom: 0;
                padding: 24px 0;
            }
        }

        .pack-game {
            display: grid;
            gap: 80px;
            grid-template-columns: repeat(12, 1fr);
            align-items: center;
            @include mq-medium {
                gap: 40px;
            }
            @include mq-small-and-less {
                gap: 0;
            }

            .information-container {
                grid-column-start: 1;
                grid-column-end: 7;
                order: -1;

                &.flex-column {
                    align-items: flex-end;

                    @include mq-small-and-less {
                        align-items: flex-start;
                    }
                }

                @include mq-medium-and-more {
                    &.reverse {
                        order: 1;
                        grid-column-start: 7;
                        grid-column-end: 13;
                        align-items: flex-start;
                    }
                }

                @include mq-small-and-less {
                    grid-column-start: 1;
                    grid-column-end: 13;
                    order: 1;
                    padding: 16px;
                }

                .description {
                    @include mq-medium-and-more {
                        &.right {
                            text-align: right;
                        }
                    }
                }

                .btn-container {
                    display: flex;
                    flex-direction: row;
                    @include mq-small-and-less {
                        &.flex-column {
                            flex-direction: column;
                            width: 100%;
                        }
                    }

                    button {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;

                        @include mq-xsmall {
                            height: 40px;
                        }

                        &.learn-more {
                            margin-left: 12px;

                            @include mq-small-and-less {
                                margin-left: 0px;
                                margin-top: 12px;
                                width: 100%;
                            }
                        }
                    }
                }

                .tag-container {
                    flex-wrap: wrap;
                }
            }

            .cropped-hero-container {
                display: inline-flex;
                grid-column-start: 7;
                grid-column-end: 13;
                order: 1;

                @include mq-medium-and-more {
                    &.reverse {
                        order: -1;
                        grid-column-start: 1;
                        grid-column-end: 7;
                        justify-self: flex-end;
                    }
                }

                @include mq-small-and-less {
                    display: flex;
                    grid-column: 1 / -1;
                }

                .cropped-hero-tile {
                    border-radius: 32px;
                    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
                    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px 0px rgba(0, 0, 0, 0.20);
                    object-fit: cover;
                    object-position: center;

                    @include mq-large {
                        height: 444px;
                        width: 600px;
                    }

                    @include mq-medium {
                        height: 352px;
                        width: 450px;
                    }

                    @include mq-small-and-less {
                        border-radius: 0px;
                        width: 100%;
                    }
                }
            }

            .wordmark {
                filter: drop-shadow(0 0 0.75rem var(--surface-500));

                img {
                    width: 100%;
                    max-height: 180px;
                    max-width: 350px;

                    @include mq-small-and-less {
                        margin-top: -220px;
                        max-width: 320px;
                        max-height: 220px;
                    }

                    @include mq-xsmall {
                        max-height: 124px;
                        &.landscape {
                            max-width: 200px;
                        }
                        &.portrait {
                            max-width: 164px;
                        }
                    }
                }
            }
        }
    }

    .media {
        @include mq-xsmall {
            margin-top: 300px;
        }

        &.mobile {
            margin-top: 12px;
        }
    }
}

::-webkit-scrollbar {
    height: 8px;
    background: none;
    padding: 30px;
}

::-webkit-scrollbar-thumb {
    background: var(--surface-900);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--primary-200);
}

</style>
